<template>
  <v-container
    fluid
    class="bg"
    align-center
    fill-height
    style="display:flex; justify-content:center;"
    pt-0
    mt-0
    pb-0
    mb-0
  >
    <v-responsive height="100%" class="hidden-sm-and-down">
      <v-container fluid fill-height pb-0>
        <v-layout align-center justify-space-around column mb-5>
          <v-flex xs12 md12 style="">
            <v-layout wrap justify-center style="text-align: center;">
              <v-flex xs12 md12 sm12 mt-2>
                <img
                  src="../assets/images/royal.png"
                  alt=""
                  width="100"
                  class="royal-image"
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap justify-center mb-2>
              <v-flex md12 sm9 xs12>
                <h1
                  class="font-shadow font-weight-bold"
                  style="text-align: center;"
                >
                  72 ล้านต้น พลิกฟื้นผืนป่า
                </h1>
                <h2
                  class="font-shadow mb-1"
                  style="text-align: center;line-height: 30px;font-size: 30px !important;font-weight: 600;"
                >
                  เฉลิมพระเกียรติพระบาทสมเด็จพระเจ้าอยู่หัว<br />เนื่องในโอกาสพระราชพิธีมหามงคลเฉลิมพระชนมพรรษา
                  6 รอบ<br />28 กรกฎาคม 2567
                </h2>
                <v-divider class="my-3"></v-divider>
                <div
                  style="display:flex; justify-content: center; margin-top: 40px; "
                >
                  <h3 class="font-shadow2" style="font-size: 30px">
                    ต้นไม้ที่ลงทะเบียนแล้ว&nbsp;
                  </h3>
                  <h3 class="el number font-shadow2" v-if="value !== null"></h3>
                  <v-progress-circular
                    v-else
                    indeterminate
                    :value="20"
                    :width="3"
                    :size="20"
                    color="white"
                    class="mt-1"
                  >
                  </v-progress-circular>
                  <h3 class="font-shadow2" style="font-size: 30px">
                    &nbsp;ต้น
                  </h3>
                </div>
              </v-flex>
              <v-flex md3 sm12 xs12 mt-5>
                <v-btn
                  class="mx-0"
                  round
                  block
                  color="primary"
                  @click="gotoIndex()"
                >
                  <p style="font-size:23px;margin-bottom:0px">ร่วมปลูกต้นไม้</p>
                </v-btn>
                <div
                  style="text-align:center;position: absolute;left: 0;bottom: 0;"
                >
                  <img src="../assets/images/logo.png" alt="" width="200" />
                  <!-- <v-btn flat round href="https://plant.forest.go.th/static/plant-knowledge/manual.pdf" target="_blank"><v-icon class="mr-1">chrome_reader_mode</v-icon>คู่มือการใช้งาน</v-btn> -->
                </div>
                <!-- <img src="../assets/images/logo.png" alt="" width="300px"> -->
              </v-flex>
            </v-layout>
            <!-- <v-layout row wrap>
      <v-flex xs12 md12 sm12 style="justify-content: center;display:flex;align-items:flex-end" class="footer">
        <span style="font-size: 22px; text-decoration: underline; cursor: pointer" @click="gotoIndex()">เข้าสู่เว็บไซต์</span>
      </v-flex>
      </v-layout> -->
          </v-flex>
        </v-layout>
        <!-- <div class="footer">
    <span style="font-size: 22px; text-decoration: underline; cursor: pointer" @click="gotoIndex()">เข้าสู่เว็บไซต์</span>
  </div> -->
      </v-container>
    </v-responsive>

    <v-responsive height="100%" class="hidden-md-and-up">
      <v-container fluid fill-height justify-center align-center pb-0 pt-0>
        <v-layout align-center justify-space-around column>
          <v-flex xs12 md12>
            <v-layout row wrap justify-center>
              <v-flex
                xs12
                md12
                sm12
                style="text-align:center;margin-top: 10px;margin-bottom: 10px;"
              >
                <v-img
                  :src="require('../assets/images/royal.png')"
                  alt=""
                  height="100"
                  contain
                ></v-img>
              </v-flex>
            </v-layout>
            <v-layout row wrap justify-center>
              <v-flex md10 sm10 xs10 style="text-align: center;">
                <h1
                  class="font-for-mobile-main font-shadow font-weight-bold mt-0"
                  style="margin-bottom: 5px;"
                >
                  72 ล้านต้น พลิกฟื้นผืนป่า
                </h1>
                <h2
                  class="font-for-mobile-sub font-shadow font-weight-bold mt-0 mb-1"
                >
                  เฉลิมพระเกียรติพระบาทสมเด็จพระเจ้าอยู่หัว<br />เนื่องในโอกาสพระราชพิธีมหามงคลเฉลิมพระชนมพรรษา
                  6 รอบ<br />
                  28 กรกฎาคม 2567
                </h2>
                <v-divider class="my-3"></v-divider>
                <div>
                  <h3
                    class="font-shadow2 font-for-mobile-middle"
                    style="justify-content: center;"
                  >
                    ต้นไม้ที่ลงทะเบียนแล้ว
                  </h3>
                  <h3
                    class="el font-shadow2 number-mobile"
                    style="justify-content: center;color: #71231a;"
                    v-if="value !== null"
                  ></h3>
                  <v-progress-circular
                    v-else
                    indeterminate
                    :value="20"
                    :width="3"
                    :size="20"
                    color="white"
                    class="mt-1"
                  >
                  </v-progress-circular>
                  <h3
                    class="font-shadow2 font-for-mobile-middle"
                    style="justify-content: center;"
                  >
                    ต้น
                  </h3>
                </div>
              </v-flex>
            </v-layout>
            <v-layout row wrap justify-center>
              <v-flex md9 sm6 xs6 mt-4>
                <v-btn
                  class="mx-0"
                  round
                  block
                  color="primary"
                  @click="gotoIndex()"
                >
                  <p class="mb-0">ร่วมปลูกต้นไม้</p>
                </v-btn>

                <!-- <v-btn flat round href="https://plant.forest.go.th/static/plant-knowledge/manual.pdf" target="_blank" color="white"><v-icon class="mr-1">chrome_reader_mode</v-icon>คู่มือการใช้งาน!-->
                <!-- <div class=""> -->
                <!-- <img src="../assets/images/logo.png" alt="" width="290"> -->
                <!-- </div> -->
                <div
                  class="pt-2"
                  style="text-align:center;position: absolute;bottom: 0;width: 100%;left: 0;"
                >
                  <img src="../assets/images/logo.png" alt="" width="130px" />
                </div>
              </v-flex>
            </v-layout>
            <!-- <v-layout row wrap>
      <v-flex xs12 md12 sm12 style="justify-content: center;display:flex;align-items:flex-end">
        <span style="font-size: 20px; text-decoration: underline; cursor: pointer;font-weight: bold;" @click="gotoIndex()">เข้าสู่เว็บไซต์</span>
      </v-flex>
      </v-layout> -->
          </v-flex>
        </v-layout>
      </v-container>
    </v-responsive>
  </v-container>
</template>

<script>
// import _ from "lodash";

var $ = require("jquery");
export default {
  data() {
    return {
      startValue: 0,
      logoHeight: "",
      interval: null,
      value: null,
      value_new: null,
      currNum: null,
      fresh: true,
      time: 1000,
      stat: "",
      totalUserPlant: null,
      sumPlant: null,
      isFirsttime: true,
    };
  },
  created() {
    // this.showAlert()
    this.interval = setInterval(() => {
      this.queryNurseryPlant();
    }, 3000);
    // this.setup(10);
  },
  computed: {
    // imageHeight() {
    //   switch (this.$vuetify.breakpoint.name) {
    //     case "xs":
    //       this.logoHeight = "140px";
    //     // case "sm":
    //     //   return "180px";
    //     // case "md":
    //     //   return "150px";
    //     // case "lg":
    //     //   return "150px";
    //     // case "xl":
    //     //   return "180px";
    //   }
    // },
  },
  watch: {
    sumPlant() {
      var me = this;
      $({ someValue: me.startValue }).animate(
        { someValue: me.sumPlant },
        {
          duration: 1000,
          easing: "swing", // can be anything
          step: function() {
            // called on every step
            // Update the element's text with rounded-up value
            $(".el").text(me.commaSeparateNumber(Math.round(this.someValue)));
          },
          complete: () => {
            $(".el").text(me.commaSeparateNumber(Math.round(me.sumPlant)));
          },
        }
      );
    },
  },
  mounted() {
    // var me = this;
    // this.queryStat().then(() => {
    //   $({ someValue: me.startValue }).animate(
    //     { someValue: me.value },
    //     {
    //       duration: 1000,
    //       easing: "swing", // can be anything
    //       step: function() {
    //         // called on every step
    //         // Update the element's text with rounded-up value
    //         $(".el").text(me.commaSeparateNumber(Math.round(this.someValue)));
    //       },
    //       complete: () => {
    //         $(".el").text(me.commaSeparateNumber(Math.round(me.value)));
    //       },
    //     }
    //   );
    // });
  },

  methods: {
    allPlant() {
      // console.log(this.value);
      // console.log(this.totalUserPlant);
      this.sumPlant = parseInt(this.totalUserPlant) + parseInt(this.value);
      // console.log(this.sumPlant);
      // this.startValue = this.sumPlant;
      if (this.isFirsttime) {
        this.startValue = this.sumPlant / 10;
        this.isFirsttime = false;
      } else {
        this.startValue = this.sumPlant;
        this.isFirsttime = false;
      }
      // console.log(this.startValue);
    },
    random(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    queryNurseryPlant() {
      this.axios
        .get(process.env.VUE_APP_IPFLASK + "/nurseryTotalUserPlant")
        .then((response) => {
          console.log(response.data[0].amount);
          // var rand = this.random(1000, 1005);
          // this.value = response.data.detail[0].amount_dist;
          //value = 7936400
          this.value = response.data[0].amount;

          // this.value = rand;
          this.queryStat();
        });
    },

    gotoIndex() {
      this.$router.push("/seeding");
    },
    queryStat() {
      return new Promise((resolve) => {
        this.axios
          .post(process.env.VUE_APP_IPFLASK + "/totalUserPlant")
          .then((response) => {
            // console.log(response);
            this.totalUserPlant = response.data[0].totalUserPlant;
            // this.totalUserPlant = 7933909;
            // this.value = response.data.detail[0].amount_dist;
            // this.value = "";
            // this.startValue = this.value / 10;
            // console.log(this.totalUserPlant);
            this.allPlant();
            resolve();
          });
      });
    },
    commaSeparateNumber(val) {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      }
      return val;
    },
    // showAlert(){
    //   // this.$swal('กรมป่าไม้จะดำเนินการปิดปรับปรุงระบบ <br>ในวันที่ 7 มิถุนายน 2562 เวลา 17.00 น. เป็นต้นไป <br>และจะเปิดให้ใช้งานได้อีกครั้ง<br>ในวันที่ 8 มิถุนายน 2562 เวลา 10.00 น. <br>ขออภัยในความไม่สะดวก','','');
    //   this.$swal({
    //     // imageUrl: 'https://placeholder.pics/svg/400x200',
    //     imageUrl: require('../assets/images/announce.jpeg'),
    //     imageHeight: 300,
    //     type: '',
    //     width: 560,
    //     title: 'แจ้งปิดปรับปรุงระบบ',
    //     html: '<strong>เว็บไซต์รวมใจไทย ปลูกต้นไม้ เพื่อแผ่นดิน<br>ในวันศุกร์ที่ 7 มิถุนายน 2562 เวลา 17.00 น. เป็นต้นไป กรมป่าไม้จะดำเนินการย้ายระบบไปไว้บนคลาวด์ เพื่อเพิ่มประสิทธิภาพในการให้บริการ โดยจะไม่สามารถบันทึกข้อมูลการปลูกต้นไม้ในเว็บไซต์ได้ และเปิดให้บริการอีกครั้งในวันเสาร์ที่ 8 มิถุนายน 2562 เวลา 10.00 น. เป็นต้นไป จึงขออภัยในความไม่สะดวก',
    //     confirmButtonText:'ปิดหน้าต่าง',
    //     allowOutsideClick: false
    //   })
    // },
  },
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
.bg {
  background-image: url("../assets/images/bg722.jpg");
  background-size: cover;
  background-position: center;
  /* background-repeat: repeat-x; */
}

.font-shadow {
  text-shadow: 1px 1px 10px rgb(255, 255, 255);
  color: #7b4d0f;
  font-size: 34px !important;
}
.font-shadow2 {
  text-shadow: 4px 2px 11px rgb(255, 255, 255);
  color: #824a00;
}
.font-shadow3 {
  text-shadow: 1px 1px 11px rgb(255, 255, 255);
  color: #984d00;
  font-size: 44px !important;
  letter-spacing: 0px !important;
}

.footer {
  font-weight: 700;
  bottom: 0;
  text-align: center;
  width: 100%;
}

.display-1 {
  line-height: 30px !important;
}
/* .line37{
    line-height: 42px !important;
  } */
.font100 {
  font-size: 82px !important;
  line-height: 80px !important;
}

.line95 {
  line-height: 0.6 !important;
}

@media only screen and (max-width: 320px) {
  .font-for-mobile-main {
    font-size: 20px !important;
    line-height: 19px !important;
  }
  .font-for-mobile-middle {
    font-size: 19px !important;
    line-height: 18px;
  }
  .font-for-mobile-sub {
    font-size: 16px !important;
    line-height: 19px !important;
  }
  .number-mobile {
    font-size: 40px !important;
    font-weight: 600;
    line-height: 46px !important;
    letter-spacing: normal !important;
    font-family: "IBM Plex Sans Thai" !important;
  }
}

@media only screen and (min-width: 321px) and (max-width: 600px) {
  .font-for-mobile-main {
    font-size: 22px !important;
    line-height: 20px;
  }
  .font-for-mobile-middle {
    font-size: 20px !important;
    line-height: 20px;
  }
  .font-for-mobile-sub {
    font-size: 16px !important;
    line-height: 20px !important;
  }
  .number-mobile {
    font-size: 34px !important;
    font-weight: 600;
    line-height: 46px !important;
    letter-spacing: normal !important;
    font-family: "IBM Plex Sans Thai" !important;
  }
}

@media only screen and (min-width: 601px) {
  .font-for-mobile-main {
    font-size: 40px !important;
    line-height: 40px !important;
  }
  .font-for-mobile-middle {
    font-size: 30px !important;
    margin-top: 5px !important;
  }
  .font-for-mobile-sub {
    font-size: 26px !important;
    line-height: 30px !important;
    margin-bottom: 10px;
  }
  .number-mobile {
    font-size: 60px !important;
    font-weight: 600;
    line-height: 46px !important;
    letter-spacing: normal !important;
    font-family: "IBM Plex Sans Thai" !important;
  }
}
.number {
  font-size: 90px !important;
  font-weight: 600;
  line-height: 46px !important;
  letter-spacing: normal !important;
  font-family: "IBM Plex Sans Thai" !important;
  color: #71231a;
}

.royal-image {
  text-shadow: 1px 1px 11px rgb(255, 255, 255);
}
</style>
